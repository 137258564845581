@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

// @import "src/scss/variables";
// should be refactored
:root {
  --primary-100: #f6fafb;
  --primary-300: #d3e5eb;
  --primary-500: #96c0d0;
  --primary-600: #589bb4;
  --primary-700: #45839b;
  --secondary-300: #a7bdd6;
  --secondary-500: #517cab;
  --secondary-600: #3d5d80;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Roboto", "Helvetica", "Arila", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ReactModal__Body--open {
  overflow: hidden !important;
}
.ReactModal__Overlay {
  z-index: 999;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.PhoneInputCountry {
  // background-color: #627d8a;
  padding: 0 10px;
  margin-right: 0 !important;
}
.PhoneInput {
  width: 100%;
}
.PhoneInputInput {
  height: 100%;
  width: 100%;
  padding: 0 10px;
}

// refactor
.theme-default {
  --primary-100: #f6fafb;
  --primary-300: #d3e5eb;
  --primary-500: #96c0d0;
  --primary-600: #589bb4;
  --primary-700: #45839b;
  --secondary-300: #a7bdd6;
  --secondary-500: #517cab;
  --secondary-600: #3d5d80;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Roboto", "Helvetica", "Arila", sans-serif;
}
.irish-times {
  --primary-100: #d2fffa;
  --primary-300: #32bcad;
  --primary-500: #19a29e;
  --primary-600: #0d7a93;
  --primary-700: #0a4f5f;
  --secondary-300: #077c94;
  --secondary-500: #1e3b49;
  --secondary-600: #0f1d24;
  --neutral-100: #a4b6ca;
  --neutral-300: #8898a9;
  --neutral-500: #535d68;
  --neutral-700: #343a40;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Raleway", "Helvetica", "Arial", sans-serif;
}
.agility {
  --primary-100: #fdf2ed;
  --primary-300: #ffaa7d;
  --primary-500: #f26924;
  --primary-600: #eb5509;
  --primary-700: #da4900;
  --secondary-300: #36536e;
  --secondary-500: #1c2b39;
  --secondary-600: #0b1117;
  --neutral-100: #c8c8c8;
  --neutral-300: #999;
  --neutral-500: #565656;
  --neutral-700: #2e2e2e;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #1c2b39;
  font-family: "Poppins", sans-serif;
}

.safesol {
  --primary-100: #f4f4f4;
  --primary-300: #d97772;
  --primary-500: #e1332b;
  --primary-600: #e02219;
  --primary-700: #e00a00;
  --secondary-300: #515151;
  --secondary-500: #212121;
  --secondary-600: #0d0d0d;
  --neutral-100: #949494;
  --neutral-300: #5e5e5e;
  --neutral-500: #333;
  --neutral-700: #1c1c1c;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Axia", "Fira Sans", "Helvetica", "Arila", sans-serif;
}

.theme-orange {
  --primary-100: #feedec;
  --primary-300: #ffc4c4;
  --primary-500: #ee6983;
  --primary-600: #be5468;
  --primary-700: #a6495b;
  --secondary-300: #a95671;
  --secondary-500: #850e35;
  --secondary-600: #6a0b2a;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Comfortaa", cursive;
}
.theme-pink {
  --primary-100: #fff5e4;
  --primary-300: #97c5ff;
  --primary-500: #fc7096;
  --primary-600: #e26487;
  --primary-700: #c95978;
  --secondary-300: #b2b4ff;
  --secondary-500: #9194ff;
  --secondary-600: #7476cc;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Arial", "Helvetica", sans-serif;
}
.theme-blue {
  --primary-100: #fff4cf;
  --primary-300: #ffcb42;
  --primary-500: #ffb200;
  --primary-600: #cc8e00;
  --primary-700: #996a00;
  --secondary-300: #67a2d2;
  --secondary-500: #277bc0;
  --secondary-600: #1f6299;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Fredoka One", cursive;
}

.theme-yellow {
  --primary-100: #ffcd38;
  --primary-300: #ff8d29;
  --primary-500: #ff4949;
  --primary-600: #cc3a3a;
  --primary-700: #992b2b;
  --secondary-300: #875bb1;
  --secondary-500: #541690;
  --secondary-600: #3a0f64;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.theme-brown {
  --primary-100: #f5e8e4;
  --primary-300: #f5c7a9;
  --primary-500: #d1512d;
  --primary-600: #bc4828;
  --primary-700: #a74024;
  --secondary-300: #664359;
  --secondary-500: #411530;
  --secondary-600: #2d0e21;
  --neutral-100: #bfcbd1;
  --neutral-300: #627d8a;
  --neutral-500: #4c606a;
  --neutral-700: #283237;
  --shades-100: #fff;
  --shades-200: #000;
  --navbar-500: #fff;
  font-family: "Calibri", "Trebuchet MS", sans-serif;
}
// refactor
.custom-checkbox {
  display: grid;
  place-content: center;

  margin: 0;
  width: 1.2rem;
  height: 1.2rem;

  border: 0.15em solid var(--primary-500);
  border-radius: 0.15em;
  appearance: none;
  &::before {
    content: "";
    width: 0.7rem;
    height: 0.7rem;
    transform: scale(0);

    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    box-shadow: inset 1em 1em var(--primary-500);
  }
  &:checked::before {
    transform: scale(1);
  }
}

.swiper .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid var(--primary-500);
}
.swiper .swiper-pagination-bullet-active {
  background-color: var(--primary-500);
}

// refactor
.react-datepicker {
  width: 100% !important;
  border: none !important;
  background-color: var(--primary-500) !important;
  border-radius: 0 !important;
  height: 370px !important;
}
.react-datepicker__month-container {
  width: 100% !important;
}
.react-datepicker__header {
  border-radius: 0 !important;
  background-color: unset !important;
  border-bottom: 0 !important;
  padding: 0 !important;
}
.react-datepicker__current-month {
  padding: 10px !important;
  color: #fff !important;
  border-bottom: 1px solid var(--primary-300) !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-transform: uppercase !important;
}

.react-datepicker__navigation {
  top: 9px !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #fff !important;
  border-width: 3px 3px 0 0 !important;
  width: 12px !important;
  height: 12px !important;
}
.react-datepicker__month {
  margin: 0 !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex: 1 !important;
  width: unset !important;
  margin: 0 !important;
  aspect-ratio: 1;

  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: var(--shades-100) !important;
  border-radius: 0 !important;
}
.react-datepicker__day:hover {
  background-color: rgba(0, 0, 0, 0.35) !important;
}

.react-datepicker__day--highlighted {
  background-color: rgba(0, 0, 0, 0.15) !important;
  text-decoration: underline;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}
.react-datepicker__day--selected {
  background-color: rgba(0, 0, 0, 0.35) !important;
  text-decoration: underline;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day--today {
  background-color: var(--shades-100) !important;
  color: #45c9e9 !important;
}
.react-datepicker__day--today:hover {
  background-color: var(--shades-100) !important;
}

.react-datepicker__triangle {
  display: none;
}
// admin-setings
.react-datepicker-popper {
  width: 100%;
  z-index: 21 !important;
}
.date-picker-wrap {
  .react-datepicker__time-container {
    border: 1px solid grey !important;
  }
  .react-datepicker-popper {
    width: unset;
  }
  .react-datepicker {
    height: unset !important;
    overflow: hidden;
    background-color: unset !important;
  }
  .react-datepicker .react-datepicker__time-list::-webkit-scrollbar {
    display: none;
  }
  .react-datepicker__header--time {
    display: none;
  }
}

.pg-pagination a{
  text-decoration: none;
}